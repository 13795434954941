/* ------------------------------------------------------------
  main.scss
------------------------------------------------------------ */

html {
    height : 100%;
    -webkit-tap-highlight-color : rgba(255, 255, 255, 0);
}

body {
    margin : 0px;
    font-family : 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color : #efeeee;
    overflow-y : scroll;
    overflow-x : hidden;
}

*:focus {
    outline : none;
}

a {
    text-decoration : none;
}

/* ----------------------------------------------------------*/
/* SCSS variables */

$white : #ffffff;
$off-white : #efeeee;
$white-light : $white;
$white-shadow : #d1cdc7;

$gray-light : #e6e7e8;
$gray : #8e96a6;

$dark-blue : #325688;
$faded-blue : #7ba9cb;

$gray-green : #556666;

$gradient-white-indent : linear-gradient( 285deg, #ffffffee, #d1cdc7ee );
$box-shadow-inset-lg : inset 6px 6px 8px 0px #d1cdc755, inset -6px -6px 8px 0px #ffffffaa;
$box-shadow-inset-sm : inset 4px 4px 4px 0px #d1cdc755, inset -4px -4px 4px 0px #ffffffaa;

$button-box-shadows-lg : 6px 6px 16px 0px #d1cdc788, -6px -6px 16px 0px #ffffff;
$button-box-shadows-sm : 4px 4px 10px 0px #d1cdc788, -4px -4px 10px 0px #ffffff;

/* ----------------------------------------------------------*/

.headerDiv {
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    position : fixed;
    top : 0;
    width : 100%;
    background-color : $off-white;
    box-shadow : 0px 0px 4px 0px rgba( 0, 0, 0, 0.3 );
    z-index : 1000;
}

.headerContainer {
    display : flex;
    flex-direction : row;
    align-items : center;
}

.headerRowSpacer {
    display : flex;
    flex-grow : 1;
}

.headerRowPic {
    display : flex;
    align-items : center;
    justify-content : center;
    background : $gradient-white-indent;
}

.headerRowButton {
    display : flex;
    justify-content : center;
    align-items : center;
    text-align : center;
    background-color : $off-white;
    color : $gray;
    cursor : pointer;
}

.headerRowButtonSelected {
    /*background : $gradient-white-indent;*/
    color : $faded-blue;
}

.aboutDiv {
    display : flex;
    flex-direction : column;
    align-items : center;
    background-color : $gray-light;
}

.techDiv {
    display : flex;
    flex-direction : column;
    align-items : center;
    width : 100%;
}

.expDiv {
    display : flex;
    flex-direction : column;
    align-items : center;
    width : 100%;
}

.linksDiv {
    display : flex;
    flex-direction : column;
    align-items : center;
    width : 100%;
}

.footerDiv {
    display : flex;
    width : 100%;
    height : 280px;
    margin-top : 40px;
    background-color : $gray-light;
}

.titles {
    color : $gray;
}

.aboutInfo {
    display : flex;
    flex-direction : row;
}

.aboutInfoText {
    color : $dark-blue;
}

.inoutText {
    color : $dark-blue;
}

.techRow {
    display : flex;
    flex-direction : row;
}

.techIconDiv {
    display : flex;
    align-items : center;
    justify-content : center;
    background : $gradient-white-indent;
}

.techIconCol {
    display : flex;
    flex-direction : column;
    justify-content : center;
}

.techIconLabel {
    display : flex;
    justify-content : center;
    color : $faded-blue;
}

.expRow {
    display : flex;
    flex-direction : row;
}

.expIconCol {
    display : flex;
    flex-direction : column;
}

.expIconDiv {
    display : flex;
    align-items : center;
    justify-content : center;
    background : $gradient-white-indent;
}

.company {
    color : $dark-blue;
}

.duration {
    color : $faded-blue;
}

.workColumn {
    display : flex;
    flex-direction : column;
}

.workTitle {
    color : $dark-blue;
}

.bulletPoints {
    color : $gray-green;
}

.linksRow {
    display : flex;
    flex-direction : row;
    cursor : pointer;
}

.miscRow {
    display : flex;
    flex-direction : row;
}

.portfolioRow {
    display : flex;
    flex-direction : column;
}

.portfolioRowTitle {
    display : flex;
    flex-direction : column;
    align-items : center;
    color : $dark-blue;
}

.portfolioRowText {
    color : $gray-green;
}

.portfolioWideContainer {
    display : flex;
    flex-direction : column;
    align-items : center;
    background-position : center;
    width : 100vw;
    margin-left : calc(-50vw + 50%);
}

.portfolioInfotainer {
    display : flex;
    flex-direction : row;
    align-items : flex-start;
}

.portfolioSingle {
    display : flex;
    flex-direction : column;
    align-items : center;
}

.portfolioScreenshot {
    display : flex;
    flex-direction : row;
    justify-content : center;
}

.portfolioDescription {
    color : $dark-blue;
}

.molrevVideoBanner {}

.linksIconDiv {}

.linksIcon {}

.linksInfoCol {}

.linksName {
    color : $dark-blue;
}

.linksDescription {
    color : $faded-blue;
}

.cursorPointer {
    cursor : pointer;
}

.portfolioWhite {
    color : $white;
    margin-top : 40px;
}

/* ----------------------------------------------------------*/
/* -- Animations --*/
/* ----------------------------------------------------------*/

.techIcon {
    transition : transform 0.3s ease-in-out;
}

.techIcon:hover {
    transform : rotate( -20deg );
}

/* ----------------------------------------------------------*/
/* -- Desktop/large screens --*/
/* ----------------------------------------------------------*/

@media screen and ( min-width: 781px ) {
    .onlyMobile {
        display : none;
    }

    .headerDiv {
        height : 96px;
    }

    .headerContainer {
        width : 768px;
    }

    .headerRowPic {
        width : 72px;
        height : 72px;
        border-radius : 72px;
    }

    .headerRowButton {
        height : 28px;
        width : 120px;
        border-radius : 28px;
        margin-left : 24px;
        font-size : 14px;
        box-shadow : $button-box-shadows-lg;
    }

    .headerRowButtonSelected {
        box-shadow : $box-shadow-inset-lg;
    }

    .titles {
        font-size : 16px;
        margin-top : 32px;
        margin-bottom : 40px;
    }

    .aboutDiv {
        margin-top : 96px;
        height : 380px;
        width : 100%;
    }

    .aboutInfo {
        width : 680px;
    }

    .aboutInfoText {
        margin-left : 60px;
        margin-top : 6px;
        font-size : 18px;
        line-height : 1.3;
    }

    .inoutText {
        margin-top : 40px;
        font-size : 18px;
    }

    .techRow {
        justify-content : space-between;
        width : 680px;
        margin-bottom : 72px;
    }

    .techIconDiv {
        width : 100px;
        height : 100px;
        border-radius : 100px;
    }

    .techIcon {
        width : 96px;
    }

    .techIconLabel {
        margin-top : 16px;
        font-size : 16px;
    }

    .expRow {
        width : 720px;
        margin-bottom : 100px;
    }

    .expIconCol {
        width : 132px;
    }

    .expIconDiv {
        width : 132px;
        height : 132px;
        border-radius : 132px;
    }

    .expIcon {
        width : 128px;
    }

    .company {
        font-size : 18px;
        margin-top : 16px;
    }

    .duration {
        font-size : 14px;
        margin-top : 4px;
    }

    .workColumn {
        margin-left : 60px;
    }

    .workTitle {
        font-size : 22px;
        margin-bottom : 10px;
    }

    .bulletPoints {
        font-size : 16px;
        line-height : 1.2;
        margin-bottom : 10px;
    }

    .linksRow {
        width : 720px;
        margin-bottom : 100px;
    }

    .miscRow {
        width : 720px;
        margin-bottom : 100px;
    }

    .portfolioRow {
        width : 720px;
        margin-bottom : 100px;
    }

    .portfolioRowTitle {
        font-size : 24px;
        font-weight : 600;
        margin-bottom : 20px;
    }

    .portfolioRowText {
        font-size : 16px;
        line-height : 1.4;
        margin-bottom : 100px;
    }

    .portfolioInfotainer {
        width : 720px;
    }

    .portfolioSentence {
        margin-bottom : 20px;
    }

    .portfolioScreenshot {
        width : 50%;
    }

    .portfolioScreenRaised {
        position : relative;
        top : -60px;
    }

    .portfolioScreenRaisedFull {
        position : relative;
        top : -60px;
    }

    .portfolioDescription {
        display : flex;
        width : 50%;
        font-size : 22px;
        padding-right : 20px;
    }

    .molrevVideoBanner {
        width : 720px;
        margin-bottom : 60px;
        border-style : solid;
        border-width : 1px;
        border-color : $white;
        border-radius : 20px;
        box-shadow : 4px 6px 16px 0px #00000066;
        overflow : hidden;
    }

    .videoBanner {
        width : 720px;
    }

    .candorBkg {
         height : 613px;
    }

    .linksIconDiv {}

    .linksIcon {
        width : 96px;
    }

    .linksInfoCol {
        margin-left : 40px;
    }

    .linksName {
        font-size : 18px;
        margin-bottom : 8px;
    }

    .linksDescription {
        font-size : 16px;
        line-height : 1.2;
    }

    .scrollAnchor {
        position : relative;
        top : -100px;
    }
}

/* ----------------------------------------------------------*/
/* -- mobile/small screens --*/
/* ----------------------------------------------------------*/

@media screen and ( max-width: 780px ) {
    .onlyDesktop {
        display : none;
    }

    .headerDiv {
        height : 48px;
    }

    .headerRowPic {
        width : 36px;
        height : 36px;
        border-radius : 36px;
        padding : 2px;
        margin-right : 16px;
    }

    .headerRowButton {
        height : 20px;
        width : 46px;
        border-radius : 20px;
        margin-left : 8px;
        font-size : 9px;
        box-shadow : $button-box-shadows-sm;
    }

    .headerRowButtonSelected {
        box-shadow : $box-shadow-inset-sm;
    }

    .headerWideButton {
        width : 74px;
    }

    .titles {
        font-size : 12px;
        margin-top : 20px;
        margin-bottom : 26px;
    }

    .aboutDiv {
        margin-top : 48px;
        height : 200px;
        width : 100%;
    }

    .aboutInfo {
        padding-left : 16px;
        padding-right : 24px;
        max-width : 360px;
    }

    .coderImg {
        width : 100px;
    }

    .aboutInfoText {
        margin-left : 20px;
        margin-top : 0px;
        font-size : 10px;
        line-height : 1.3;
    }

    .inoutText {
        margin-top : 26px;
        font-size : 12px;
    }

    .techDiv {
        margin-top : 20px;
    }

    .techRow {
        justify-content : space-between;
        width : 80%;
        margin-bottom : 36px;
    }

    .techIconDiv {
        width : 50px;
        height : 50px;
        border-radius : 50px;
    }

    .techIcon {
        width : 48px;
    }

    .techIconLabel {
        margin-top : 8px;
        font-size : 9px;
    }

    .expRow {
        width : 90%;
        margin-bottom : 50px;
    }

    .expIconCol {
        min-width : 90px;
        max-width : 90px;
    }

    .expIconDiv {
        width : 66px;
        height : 66px;
        border-radius : 66px;
    }

    .expIcon {
        width : 64px;
    }

    .company {
        font-size : 12px;
        margin-top : 10px;
    }

    .duration {
        font-size : 9px;
        margin-top : 3px;
    }

    .workColumn {
        margin-left : 24px;
    }

    .workTitle {
        font-size : 14px;
        margin-bottom : 8px;
    }

    .bulletPoints {
        font-size : 11px;
        line-height : 1.2;
        margin-bottom : 8px;
    }

    .linksRow {
        width : 90%;
        margin-bottom : 50px;
    }

    .miscRow {
        width : 90%;
        margin-bottom : 50px;
    }

    .portfolioRow {
        width : 90%;
        margin-bottom : 50px;
    }

    .portfolioRowTitle {
        font-size : 16px;
        margin-bottom : 8px;
    }

    .portfolioRowText {
        font-size : 11px;
        line-height : 1.4;
        margin-bottom : 80px;
    }

    .portfolioInfotainer {
        justify-content : center;
    }

    .portfolioScreenshot {
        width : 50%;
    }

    .portfolioScreenRaised {
        position : relative;
        top : -54px;
        max-width : 300px;
        width : 100%;
    }

    .portfolioScreenRaisedFull {
        position : relative;
        top : -54px;
        max-width : 600px;
        width : 100%;
    }

    .portfolioDescription {
        width : 50%;
    }

    .videoBanner {
        width : 100%;
    }

    .molrevVideoBanner {
        width : 100%;
        margin-bottom : -4px;
    }

    .candorBkg {
         height : 300px;
         background-size : auto 300px;
    }

    .linksIconDiv {}

    .linksIcon {
        width : 48px;
    }

    .linksInfoCol {
        margin-left : 28px;
    }

    .linksName {
        font-size : 14px;
        margin-bottom : 6px;
    }

    .linksDescription {
        font-size : 11px;
        line-height : 1.2;
    }

    .scrollAnchor {
        position : relative;
        top : -60px;
    }
}

/* ----------------------------------------------------------*/
